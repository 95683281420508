<template>
  <div class="popup">
    <div class="page-title">
      <span>基本信息</span>
    </div>
    <div class="content">
      <div @click="close" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="title">居民信息</div>
      <div class="info">
        <div class="item">
          <div class="key">居民信息</div>
          <div class="value">20200908900</div>
        </div>
        <div class="item item1">
          <div class="key">姓名</div>
          <div class="value">李明明</div>
        </div>
        <div class="item">
          <div class="key">头像</div>
          <img class="img" src="../img/ssjk/user.png" alt="头像">
        </div>
        <div class="item">
          <div class="key">身份证号</div>
          <div class="value">130730199803010808</div>
        </div>

        <div class="item">
          <div class="key">联系电话</div>
          <div class="value">15803339264</div>
        </div>
        <div class="item item1">
          <div class="key">现居地</div>
          <div class="value">河北石家庄桥西区友谊街道202号</div>
        </div>
        <div class="item">
          <div class="key">登录密码</div>
          <div class="value">ha138900</div>
        </div>
        <div class="item">
          <div class="key">行政区划</div>
          <div class="value">130730199803010808</div>
        </div>

        <div class="item">
          <div class="key">居民标签</div>
          <div class="label">
            <span>高血压</span>
            <span>糖尿病</span>
          </div>
        </div>
        <div class="item item1">
          <div class="key">性别</div>
          <div class="value">女</div>
        </div>
        <div class="item">
          <div class="key">出生日期</div>
          <div class="value">1970-09-08</div>
        </div>
        <div class="item">
          <div class="key">籍贯</div>
          <div class="value">北京</div>
        </div>

        <div class="item">
          <div class="key">民族</div>
          <div class="value">汉</div>
        </div>
        <div class="item item1">
          <div class="key">文化程度</div>
          <div class="value">本科</div>
        </div>
        <div class="item">
          <div class="key">婚姻状况</div>
          <div class="value">已婚</div>
        </div>
        <div class="item">
          <div class="key">职位</div>
          <div class="value">公务员</div>
        </div>

        <div class="item">
          <div class="key">工作单位</div>
          <div class="value">无</div>
        </div>
        <div class="item item1">
          <div class="key key1">紧急联系人</div>
          <div class="value">无</div>
        </div>
        <div class="item">
          <div class="key key1">联系人电话</div>
          <div class="value">无</div>
        </div>
        <div class="item">
          <div class="key">户籍类型</div>
          <div class="value">城镇</div>
        </div>

        <div class="item">
          <div class="key">费用类型</div>
          <div class="value">自费</div>
        </div>
        <div class="item item1">
          <div class="key key2">定点医疗单位</div>
          <div class="value">怀柔区第一人民医院</div>
        </div>
        <div class="item">
          <div class="key">来源</div>
          <div class="value">用户注册</div>
        </div>
      </div>

      <div class="title">健康信息</div>
      <div class="info info1">
        <div class="item">
          <div class="key">身高</div>
          <div class="value">168cm</div>
        </div>
        <div class="item">
          <div class="key">体重</div>
          <div class="value">49kg</div>
        </div>
        <div class="item">
          <div class="key">血型</div>
          <div class="value">A性</div>
        </div>
        <div class="item">
          <div class="key">RH阴性</div>
          <div class="value">否</div>
        </div>
        <div class="item">
          <div class="key">过敏史</div>
          <div class="value">无</div>
        </div>
        <div class="item">
          <div class="key">既往史</div>
          <div class="value">无</div>
        </div>
        <div class="item">
          <div class="key">就诊史</div>
          <div class="value">无</div>
        </div>
        <div class="item">
          <div class="key">家族变史</div>
          <div class="value">无</div>
        </div>
      </div>
      <div class="bts">
        <!-- <span>取消</span>
        <span class="update">确定</span> -->
        <el-button size="small" @click="close">取消</el-button>
        <el-button type="primary" size="small" @click="close">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  mounted () { },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  background-color: #333;

  .page-title {
    width: 267px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    min-width: 800px;
    height: calc(100% - 63px);
    margin-top: 10px;
    padding: 50px 65px;
    padding-right: 100px;
    background-color: #fff;
    // background-image: url('../img/dialogContentBg.png');
    // background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 30px;
      right: 32px;
      color: #fff;
      font-size: 34px;
      cursor: pointer;
    }

    .title {
      height: 45px;
      line-height: 45px;
      border-bottom: 1px solid #000;
      box-sizing: border-box;
      color: #000;
      font-size: 18px;
    }

    .info {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      height: 250px;
      margin-top: 10px;
      padding: 0 18px;
      box-sizing: border-box;

      .item {
        display: flex;
        align-items: center;
        width: 21%;
        color: #000;
        font-size: 16px;

        .key {
          letter-spacing: normal;
          width: 70px;
          margin-right: 30px;
          text-align: justify;
          text-align-last: justify;
          white-space: nowrap;
        }

        .value {
          white-space: pre;
        }

        .key1 {
          width: 90px;
        }

        .key2 {
          width: 110px;
        }

        .img {
          width: 25px;
          height: 25px;
        }

        .label {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 116px;
          height: 22px;

          span {
            width: 52px;
            height: 22px;
            line-height: 22px;
            text-align: center;
            color: #fff;
            font-size: 16px;
            background-color: #0170C2;
            white-space: nowrap;
          }
        }
      }

      .item1 {
        width: 30%;
      }
    }

    .info1 {
      height: 60px;
    }

    .bts {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 240px;
      height: 120px;
      margin: 0 auto;
    }
  }
}
</style>
