<template>
  <div class="assess">
    <div class="scroll">
      <div class="flex">
        <div class="line">
      <div class="left">
        <span class="left-title">您的血脂异常危险度评估结果为：</span>
        <div class="box">
          <div class="item"></div>
          <div class="item"></div>
          <div class="item"></div>
          <img class="img img2" src="../../img/ssjk/man.png" alt="人物">
        </div>
        <div class="text">
          <span>低位人群</span>
          <span>高危人群</span>
          <span>血脂异常</span>
        </div>
      </div>
      <div class="right">
        <div class="font">根据《中国成人血脂异常防治指南》对血脂异常的定义：血清甘油三酯≥1.70mmol/L，总胆固醇≥5.18mmol/L，高密度脂蛋白胆固醇&lt;1.04mmol/L，低密度脂蛋白胆固醇≥3.37mmol/L，4项中具备1项及以上者即为血脂异常。
        </div>
        <div class="font">根据您提供的有关信息及临床检查结果，您达到血脂异常的
诊断标准。</div>
      </div>
    </div>
    <div class="line-title">您的膳食评价结果为：轻度膳食不良</div>
    <div class="line-title">与血脂异常发病相关的危险因素</div>
    <div class="tables">
      <div class="tr">
        <span>分类</span>
        <span>本次</span>
        <span>本次日期</span>
        <span>上次</span>
        <span>上次日期</span>
        <span>变化情况</span>
        <span>参考值</span>
      </div>
      <div class="tr">
        <span>甘油三脂</span>
        <span>38</span>
        <span>...</span>
        <span>...</span>
        <span>...</span>
        <span>...</span>
        <span>&lt;1.7mmol/L</span>
      </div>
      <div class="tr">
        <span>总胆固醇</span>
        <span>38</span>
        <span>...</span>
        <span>...</span>
        <span>...</span>
        <span>...</span>
        <span>&lt;1.7mmol/L</span>
      </div>
      <div class="tr">
        <span>高密度脂蛋白</span>
        <span>38</span>
        <span>...</span>
        <span>...</span>
        <span>...</span>
        <span>...</span>
        <span>&lt;1.7mmol/L</span>
      </div>
      <div class="tr">
        <span>低密度脂蛋白</span>
        <span>38</span>
        <span>...</span>
        <span>...</span>
        <span>...</span>
        <span>...</span>
        <span>&lt;1.7mmol/L</span>
      </div>
    </div>
    <div class="line-title1">指导建议</div>
    <div class="div">血脂检查通常共4项，即总胆固醇、甘油三酯、低密度脂蛋白胆固醇和高密度脂蛋白胆固醇。 血脂异常就是指血中总胆固醇、甘油三酯、低密度脂蛋白胆固醇超过正常及（或）高密度脂 蛋白胆 固醇低下。 血脂异常作为脂代谢障碍的表现，属于代谢性疾病，它对健康的损害主 要在心血管系统，易导致冠心病及其他动脉粥样硬化性疾病。 您的血脂异常危险等级为高危。建议您生活中 减少动物性脂肪如猪油,肥猪肉,黄油等,这类食物饱和脂肪酸过多,脂肪容易沉积在血管壁上,增加血液的粘稠度。 还应多吃一些含有纤维的食物,如蘑菇、木耳、洋葱、南瓜等。要控制食物的总量 ，尤其要限制糖类食物及适当限制动物脂肪和胆固醇的摄入。</div>
    <!-- <div class="line-title1">运动建议</div>
    <div class="div">每天坚持30分钟有氧运动</div>
    <div class="line-title1">生活建议</div>
    <div class="div">作息规律，保持积极乐观的心态</div> -->
      </div>
    </div>

    <div class="bts">
      <span class="update" @click="closePopup">保存</span>
      <span @click="closePopup">返回</span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.assess {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0A2D52;

  .scroll {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 40px);
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .flex {
    display: flex;
    flex-direction: column;
  }

  div {
    color: #01EEFD;
  }

  .line {
    display: flex;
    align-items: center;
    width: 100%;
    height: 352px;
    margin-top: 20px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .left,
    .right {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      position: relative;
      width: 50%;
      height: 340px;

      .left-title {
        position: absolute;
        left: 60px;
        top: 20px;
        color: #01EEFD;
        font-size: 18px;
      }

      .box {
        display: flex;
        align-items: flex-end;
        position: relative;
        height: 160px;

        .img {
          position: absolute;
          bottom: -10px;
          left: 75px;
          transform: translateX(-50%);
          width: 60px;
          height: 100px;
        }

        .img1 {
          position: absolute;
          bottom: 40px;
          left: 225px;
        }

        .img2 {
          position: absolute;
          bottom: 90px;
          left: 375px;
        }

        .img3 {
          position: absolute;
          bottom: 140px;
          left: 530px;
        }

        .item {
          width: 150px;
          height: 10px;
          background-color: #00DB00;

          &:nth-child(2) {
            height: 60px;
            background-color: #FFDC35;
          }

          &:nth-child(3) {
            height: 110px;
            background-color: #FF0000;
          }
        }
      }

      .text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 450px;
        margin-top: 7px;

        span {
          flex: 1;
          text-align: center;
          color: #01EEFD;
          font-size: 16px;
        }
      }

      .font {
        width: 480px;
        word-break: break-all;
        color: #01EEFD;
        font-size: 18px;
        &:nth-child(2) {
          margin-top: 25px;
        }
      }
    }

    .left {
      box-sizing: border-box;
      border-right: 1px solid #01EEFD;

    }

    .right {
      padding: 0 19px;
      box-sizing: border-box;
    }
  }

  .line-title {
    display: flex;
    align-items: center;
    margin-top: 28px;
    margin-left: 44px;
    font-size: 18px;
  }

  .tables {
    width: 98%;
    height: 155px;
    margin-top: 20px;
    margin-left: 44px;
    border-left: 1px solid #01EEFD;
    border-top: 1px solid #01EEFD;
    box-sizing: border-box;

    .tr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 31px;
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;
      font-size: 14px;

      span {
        flex: 1;
        height: 31px;
        line-height: 31px;
        text-align: center;
        box-sizing: border-box;
        border-right: 1px solid #01EEFD;
      }
    }
  }

  .line-title1 {
    margin-top: 28px;
    margin-left: 54px;
    font-size: 18px;
  }

  .div {
    width: 90%;
    height: 120px;
    margin: 0 auto;
    margin-top: 20px;
    padding: 9px 8px;
    box-sizing: border-box;
    border: 1px solid #01EEFD;
    font-size: 14px;
  }
}

.bts {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    height: 26px;
    margin-left: 18px;

    span {
      height: 26px;
      line-height: 26px;
      padding: 0 18px;
      color: #fff;
      font-size: 14px;
      background-color: #D5D5D5;
      cursor: pointer;
    }

    .update {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
</style>
