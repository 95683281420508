<template>
  <div class="assess">
    <div class="assess-tab">
      <div v-for="(item, index) in tabs" :key="index" class="tab-item" :class="{ 'tab-item1': tabIndex === index }">
        <span>{{ item }}</span>
      </div>
    </div>
    <div v-if="tabIndex === 0" class="content">
      <div class="font">【中医体质测试】是根据《中医体质分类与判定》的体质判定标准研发而成，
        该文件是我国第一部指导和规范中医体质研究及应用文件，旨在为体质辨识及与中医体质相关疾病的防治、养生保健、健康管理提供依据，使体质分类科学化、规范化。</div>
      <div class="font">该标准将体质分为平和质、气虚质、阳虚质、阴虚质、痰湿质、湿热质、血瘀质、气郁质、特禀质九个类型。</div>
      <div class="font">想了解自己属于哪种中医体质吗？想知道如何针对自己的中医体质进行中医养生吗？本系统将根据您的近期身体状况作出体质判断，并精心为您准备相应的养生方案。</div>
      <div class="start">
        <el-button @click="start">开始测试</el-button>
      </div>
    </div>
    <div v-else-if="tabIndex === 1" class="content">
      <div class="flex" v-show="nextIndex === 0">
        <div class="table">
          <div class="tr">
            <span class="td td1">请根据近一年的体验和感觉，回答以下问题</span>
            <span class="td">没有<br />(根本不)</span>
            <span class="td">很少<br />(有一点)</span>
            <span class="td">有时<br />(有些)</span>
            <span class="td">经常<br />(相当)</span>
            <span class="td">总是<br />(非常)</span>
          </div>
          <div v-for="(item, index) in list1" :key="index" class="tr">
            <span class="td td1">{{ item }}</span>
            <div v-for="(items, indexs) in 5" :key="indexs" class="td">
              <span class="div" :class="{ div1: indexs === 0 }">{{ items }}</span>
            </div>
          </div>
        </div>
        <div class="tip">当前：第1/9组 【阴虚质】中医体质测试 （本组共5题） 完整测试需9组题目，为了更准确的测出您的体质，请耐心进行。</div>
        <div class="tip red">阴虚体质的人常有热象表现，经常口渴，喉咙干、容易心烦、气躁、脾气差容易失眠、头昏眼花，皮肤枯燥无光泽、盗汗、形体消瘦、小便黄、手足易冒汗发热、粪便硬、常便秘等。</div>
      </div>
      <div class="flex" v-show="nextIndex === 1">
        <div class="table">
          <div class="tr">
            <span class="td td1">请根据近一年的体验和感觉，回答以下问题</span>
            <span class="td">没有<br />(根本不)</span>
            <span class="td">很少<br />(有一点)</span>
            <span class="td">有时<br />(有些)</span>
            <span class="td">经常<br />(相当)</span>
            <span class="td">总是<br />(非常)</span>
          </div>
          <div v-for="(item, index) in list2" :key="index" class="tr">
            <span class="td td1">{{ item }}</span>
            <div v-for="(items, indexs) in 5" :key="indexs" class="td">
              <span class="div" :class="{ div1: indexs === 0 }">{{ items }}</span>
            </div>
          </div>
        </div>
        <div class="tip">当前：第2/9组 【阳虚质】中医体质测试 （本组共7题） 完整测试需9组题目，为了更准确的测出您的体质，请耐心进行。</div>
        <div class="tip red">阳虚体质的人平时比较怕冷，不爱说话，口唇的颜色也较白，手脚常冰冷，而且睡眠也偏多 。</div>
      </div>
      <div class="flex" v-show="nextIndex === 2">
        <div class="table">
          <div class="tr">
            <span class="td td1">请根据近一年的体验和感觉，回答以下问题</span>
            <span class="td">没有<br />(根本不)</span>
            <span class="td">很少<br />(有一点)</span>
            <span class="td">有时<br />(有些)</span>
            <span class="td">经常<br />(相当)</span>
            <span class="td">总是<br />(非常)</span>
          </div>
          <div v-for="(item, index) in list3" :key="index" class="tr">
            <span class="td td1">{{ item }}</span>
            <div v-for="(items, indexs) in 5" :key="indexs" class="td">
              <span class="div" :class="{ div1: indexs === 0 }">{{ items }}</span>
            </div>
          </div>
        </div>
        <div class="tip">当前：第3/9组 【痰湿质】中医体质测试 （本组共8题） 完整测试需9组题目，为了更准确的测出您的体质，请耐心进行。</div>
        <div class="tip red">痰湿体质是目前比较常见的一种体质类型，多见于肥胖人，或以前偏瘦后来发胖的人。常表现有腹部肥满松软，体形肥胖，多汗且粘，面部皮肤油脂较多，面色淡黄而暗，胸闷，痰多，容易困倦。</div>
      </div>
      <div class="flex" v-show="nextIndex === 3">
        <div class="table">
          <div class="tr">
            <span class="td td1">请根据近一年的体验和感觉，回答以下问题</span>
            <span class="td">没有<br />(根本不)</span>
            <span class="td">很少<br />(有一点)</span>
            <span class="td">有时<br />(有些)</span>
            <span class="td">经常<br />(相当)</span>
            <span class="td">总是<br />(非常)</span>
          </div>
          <div v-for="(item, index) in list4" :key="index" class="tr">
            <span class="td td1">{{ item }}</span>
            <div v-for="(items, indexs) in 5" :key="indexs" class="td">
              <span class="div" :class="{ div1: indexs === 0 }">{{ items }}</span>
            </div>
          </div>
        </div>
        <div class="tip">当前：第4/9组 【气虚质】中医体质测试 （本组共6题） 完整测试需9组题目，为了更准确的测出您的体质，请耐心进行。</div>
        <div class="tip red">气虚体质的人常形体消瘦或偏胖，面色也比较苍白，身体容易疲倦乏力，常自汗出，说话声音低怯，心跳较快，且动则尤甚，食量偏少，舌头颜色淡、舌苔白，脉虚弱。</div>
      </div>
      <div class="flex" v-show="nextIndex === 4">
        <div class="table">
          <div class="tr">
            <span class="td td1">请根据近一年的体验和感觉，回答以下问题</span>
            <span class="td">没有<br />(根本不)</span>
            <span class="td">很少<br />(有一点)</span>
            <span class="td">有时<br />(有些)</span>
            <span class="td">经常<br />(相当)</span>
            <span class="td">总是<br />(非常)</span>
          </div>
          <div v-for="(item, index) in list5" :key="index" class="tr">
            <span class="td td1">{{ item }}</span>
            <div v-for="(items, indexs) in 5" :key="indexs" class="td">
              <span class="div" :class="{ div1: indexs === 0 }">{{ items }}</span>
            </div>
          </div>
        </div>
        <div class="tip">当前：第5/9组 【血瘀质】中医体质测试 （本组共6题） 完整测试需9组题目，为了更准确的测出您的体质，请耐心进行。</div>
        <div class="tip red">血瘀体质是指血行不畅，舌质紫暗、肤色晦暗等血瘀表现为主要特征的体质状态。</div>
      </div>
      <div class="flex" v-show="nextIndex === 5">
        <div class="table">
          <div class="tr">
            <span class="td td1">请根据近一年的体验和感觉，回答以下问题</span>
            <span class="td">没有<br />(根本不)</span>
            <span class="td">很少<br />(有一点)</span>
            <span class="td">有时<br />(有些)</span>
            <span class="td">经常<br />(相当)</span>
            <span class="td">总是<br />(非常)</span>
          </div>
          <div v-for="(item, index) in list6" :key="index" class="tr">
            <span class="td td1">{{ item }}</span>
            <div v-for="(items, indexs) in 5" :key="indexs" class="td">
              <span class="div" :class="{ div1: indexs === 0 }">{{ items }}</span>
            </div>
          </div>
        </div>
        <div class="tip">当前：第6/9组 【湿热质】中医体质测试 （本组共6题） 完整测试需9组题目，为了更准确的测出您的体质，请耐心进行。</div>
        <div class="tip red">湿热体质是指湿热内蕴，口苦苔黄腻、 以面垢油光等湿热表现为主要特征的体质状态。</div>
      </div>
      <div class="flex" v-show="nextIndex === 6">
        <div class="table">
          <div class="tr">
            <span class="td td1">请根据近一年的体验和感觉，回答以下问题</span>
            <span class="td">没有<br />(根本不)</span>
            <span class="td">很少<br />(有一点)</span>
            <span class="td">有时<br />(有些)</span>
            <span class="td">经常<br />(相当)</span>
            <span class="td">总是<br />(非常)</span>
          </div>
          <div v-for="(item, index) in list7" :key="index" class="tr">
            <span class="td td1">{{ item }}</span>
            <div v-for="(items, indexs) in 5" :key="indexs" class="td">
              <span class="div" :class="{ div1: indexs === 0 }">{{ items }}</span>
            </div>
          </div>
        </div>
        <div class="tip">当前：第7/9组 【气郁质】中医体质测试 （本组共6题） 完整测试需9组题目，为了更准确的测出您的体质，请耐心进行。</div>
        <div class="tip red">气郁体质是指气机郁滞，以忧虑脆弱、神情抑郁等气郁表现为主要特征的体质状态。</div>
      </div>
      <div class="flex" v-show="nextIndex === 7">
        <div class="table">
          <div class="tr">
            <span class="td td1">请根据近一年的体验和感觉，回答以下问题</span>
            <span class="td">没有<br />(根本不)</span>
            <span class="td">很少<br />(有一点)</span>
            <span class="td">有时<br />(有些)</span>
            <span class="td">经常<br />(相当)</span>
            <span class="td">总是<br />(非常)</span>
          </div>
          <div v-for="(item, index) in list8" :key="index" class="tr">
            <span class="td td1">{{ item }}</span>
            <div v-for="(items, indexs) in 5" :key="indexs" class="td">
              <span class="div" :class="{ div1: indexs === 0 }">{{ items }}</span>
            </div>
          </div>
        </div>
        <div class="tip">当前：第8/9组 【阳虚质】中医体质测试 （本组共7题） 完整测试需9组题目，为了更准确的测出您的体质，请耐心进行。</div>
        <div class="tip red">特禀体质是指先天失常，以过敏反应、生理缺陷等为主要特征的体质状态。</div>
      </div>
      <div class="flex" v-show="nextIndex === 8">
        <div class="table">
          <div class="tr">
            <span class="td td1">请根据近一年的体验和感觉，回答以下问题</span>
            <span class="td">没有<br />(根本不)</span>
            <span class="td">很少<br />(有一点)</span>
            <span class="td">有时<br />(有些)</span>
            <span class="td">经常<br />(相当)</span>
            <span class="td">总是<br />(非常)</span>
          </div>
          <div v-for="(item, index) in list9" :key="index" class="tr">
            <span class="td td1">{{ item }}</span>
            <div v-for="(items, indexs) in 5" :key="indexs" class="td">
              <span class="div" :class="{ div1: indexs === 0 }">{{ items }}</span>
            </div>
          </div>
        </div>
        <div class="tip">当前：第9/9组 【平和质】中医体质测试 （本组共8题） 完整测试需9组题目，为了更准确的测出您的体质，请耐心进行。</div>
        <div class="tip red">平和体质为正常体质</div>
      </div>

      <div class="next" :class="{next1: nextIndex === 0}">
          <el-button @click="upper" v-if="nextIndex > 0">上一项</el-button>
          <el-button @click="lower" v-if="nextIndex < 8">下一项</el-button>
          <el-button @click="see" v-if="nextIndex === 8">查看结果</el-button>
        </div>
    </div>
    <div v-else-if="tabIndex === 2" class="content">
      <AssessZw></AssessZw>
    </div>
    <div class="bts">
      <!-- <span v-if="tabIndex === 2" class="update" @click="again">重新评估</span> -->
      <!-- <span @click="closePopup">返回</span> -->
      <el-button v-if="tabIndex === 2" type="primary" size="small" @click="again">重新评估</el-button>
      <el-button size="small" @click="closePopup">返回</el-button>
    </div>
  </div>
</template>

<script>
import AssessZw from '../basicInfo/assessZw.vue'
export default {
  components: {
    AssessZw
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['介绍说明', '测试题目', '查看结果']),
      nextIndex: 0,
      list1: Object.freeze(['1、您手脚发凉吗？', '2、您胃脘部、背部或腰膝部怕冷吗？', '3、您感到怕冷、衣服比别人穿得多吗？', '4、你受凉或吃（喝）凉的东西后，容易腹泻（拉肚子）吗？', '5、您吃（喝）凉的东西会感到不舒服或者怕吃（喝）凉东西吗？']),
      list2: Object.freeze(['1、您感到手脚心发热吗？', '2、您感觉身体、脸上发热吗?', '3、您皮肤或口唇干燥吗？', '4、您容易便秘或大便干燥吗？', '5、您面部两颧潮红或偏红吗？', '6、您感到眼睛干涩吗？', '7、您口唇的颜色比一般人红吗？']),
      list3: Object.freeze(['1、您感到胸闷或腹部胀满吗？', '2、您感到身体沉重不轻松或不爽快吗？', '3、您腹部肥满松软吗？', '4、您舌苔厚腻或有舌苔厚厚的感觉吗？', '5、您上眼睑比别人肿（仍轻微隆起的现象）吗？', '6、您嘴里有黏黏的感觉吗？', '7、您平时痰多，特别是咽喉部总感到有痰堵着吗？', '8、您有额部油脂分泌多的现象吗？']),
      list4: Object.freeze(['1、您容易气短（呼吸短促，接不上气）吗？', '2、您容易心慌吗？', '3、您活动量稍大就容易出虚汗吗？', '4、您比别人容易患感冒吗？', '5、您喜欢安静、懒得说话吗？', '6、您容易头晕或站起时晕眩吗？']),
      list5: Object.freeze(['1、您的皮肤在不知不觉中会出现青紫瘀斑（皮下出血）吗？', '2、您两颧部有细微红丝吗？', '3、您身体上有哪里疼痛吗？', '4、您口唇颜色偏黯吗？', '5、您容易有黑眼圈吗？', '6、您面色晦黯或容易出现褐斑吗？']),
      list6: Object.freeze(['1、您面部或鼻部有油腻感或者油亮发光吗？', '2、你容易生痤疮或疮疖吗？', '3、您带下色黄（白带颜色发黄）吗？（限女性回答） 或者 您的阴囊部位潮湿吗？（限男性回答）', '4、您大便黏滞不爽、有解不尽的感觉吗？', '5、您小便时尿道有发热感、尿色浓（深）吗？', '6、您感到口苦或嘴里有异味吗？']),
      list7: Object.freeze(['1、您容易精神紧张、焦虑不安吗？', '2、您多愁善感、感情脆弱吗？', '3、您咽喉部有异物感，且吐之不出、咽之不下吗？', '4、你胸肋两侧有窜痛或乳房有胀痛的感觉吗？', '5、您无缘无故叹气吗？', '6、您容易感到害怕或受到惊吓吗？']),
      list8: Object.freeze(['1、您没有感冒时也会打喷嚏吗？', '2、您没有感冒时也会鼻塞、流鼻涕吗？', '3、您有因季节变化、温度变化或异味等原因而咳喘的现象吗？', '4、您的皮肤一抓就红，并出现抓痕吗？', '5、您的皮肤容易起荨麻疹（风团、风疹块、风疙瘩）吗？', '6、您的皮肤因过敏出现过紫癜（紫红色瘀点、瘀斑）吗？', '7、您容易过敏（对药物、食物、气味、花粉或在季节交替、气候变化时）吗？']),
      list9: Object.freeze(['1、您精力充沛吗？', '2、您容易疲乏吗？', '3、您说话声音无力吗？', '4、您容易忘事（健忘）吗？', '5、您比一般人耐受不了寒冷（冬天的寒冷，夏天的冷空调、电扇等）吗？', '6、您能适应外界自然和社会环境的变化吗', '7、您容易失眠吗？', '8、您感到闷闷不乐吗？'])
    }
  },
  methods: {
    start () {
      this.tabIndex = 1
    },
    upper () {
      if (this.nextIndex === 0) return
      this.nextIndex--
    },
    lower () {
      if (this.nextIndex === 13) return
      this.nextIndex++
    },
    see () {
      this.tabIndex = 2
      this.nextIndex = 0
    },
    again () {
      this.tabIndex = 0
      this.nextIndex = 0
      // 记着所有数据初始化
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.assess {
  width: 100%;
  height: 100%;
  // padding-top: 10px;
  box-sizing: border-box;

  .assess-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: 50px;
    margin: 0 auto;
    margin-top: 10px;

    .tab-item {
      width: 32%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      background-size: 100% 100%;
      color: #fff;
      font-size: 20px;
      background-image: url('../../img/ssjk/background2.png');
    }

    .tab-item1 {
      background-image: url('../../img/ssjk/background1.png');
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: calc(100% - 120px);
    margin: 0 auto;
    margin-top: 20px;

    .flex {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .font {
      margin-bottom: 20px;
      color: #000;
      font-size: 20px;
    }

    .next {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 94%;
      height: 43px;
      margin: 0 auto;
      margin-top: 10px;
    }

    .next1 {
      justify-content: center;
    }

    .start {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 43px;
    }

    .el-button {
      width: 393px;
      height: 43px;
      line-height: 43px;
      padding: 0;
      border: 0;
      margin: 0 auto;
      background-color: #01EEFD;
      font-size: 18px;
      color: #fff;
      text-align: center;
    }

    .table {
      display: flex;
      flex-direction: column;
      width: 99%;
      margin: 0 auto;
      border-top: 1px solid #000;
      border-left: 1px solid #000;
      box-sizing: border-box;

      .tr {
        display: flex;
        align-items: center;
        width: 100%;
        height: 45px;

        .td {
          flex: 1;
          display: flex;
          height: 45px;
          text-align: center;
          align-items: center;
          justify-content: center;
          color: #000;
          font-size: 14px;
          border-right: 1px solid #000;
          border-bottom: 1px solid #000;
          box-sizing: border-box;

          .div {
            width: 58px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            color: #fff;
            font-size: 20px;
            background-color: #D2D2D2;
          }

          .div1 {
            background-color: #000;
          }
        }

        .td1 {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          flex: 4;
          justify-content: flex-start;
          padding: 0 11px;
        }
      }
    }

    .tip {
      width: 94%;
      margin: 0 auto;
      margin-top: 7px;
      padding-left: 13px;
      box-sizing: border-box;
      color: #000;
      font-size: 14px;
    }

    .red {
      color: #FF0000;
    }
  }

  .bts {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    height: 26px;
    margin-left: 18px;

    span {
      height: 26px;
      line-height: 26px;
      padding: 0 18px;
      color: #fff;
      font-size: 14px;
      background-color: #D5D5D5;
      cursor: pointer;
    }

    .update {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>
