<template>
  <div class="assess">
    <div class="switch">
      <span class="title">您的主要体质为：</span>
      <span v-for="(item, index) in switchs" :key="index" class="item" :class="{ item1: switchIndex === index }" @click="switchClick(index)">{{ item }}</span>
    </div>
    <div class="content" v-show="switchIndex === 0">
      <div class="title">湿热质养生说</div>
      <div class="p">什么是湿热体质</div>
      <div class="line">特点与症状：面部和鼻尖总是油光发亮，易生粉刺、疮疖，常感到口苦、口臭或嘴里有异味，经常大便粘滞不爽，小便有发热感，尿色发黄，女性常带下色黄，男性阴囊总是潮湿多汗。</div>
      <div class="line">性格：湿热体质的人性格多急燥易怒。</div>
      <div class="line">体型：湿热体质的人形体偏胖或苍瘦。</div>
      <div class="line">湿热体质的亚疾病状态及发病倾向-湿热体质的人易患疮疖、黄疸、火热病等征</div>
      <div class="line">湿热体质对环境的适应能力-湿热体质的人对湿环境或气温偏高，尤其夏末秋初，湿热交蒸气候较难适应。</div>
      <div class="p">湿热体质的养生方法 -湿热体质的饮食宜与忌：</div>
      <div class="line">宜：饮食以清淡为原则，可多食甘寒、甘平的食物：</div>
      <div class="line">适宜吃的菜类：空心菜、苋菜、芹菜、黄瓜、丝瓜、葫芦、冬瓜、藕；</div>
      <div class="line">适宜吃的水产品：海蜇；</div>
      <div class="line">适宜吃的豆类：绿豆、赤小豆</div>
      <div class="line">忌：少食甘酸滋腻之品及火锅、烹炸、烧烤等辛湿助热的食物：</div>
      <div class="line">不适宜吃的菜类：韭菜、生姜、芫荽</div>
      <div class="line">不适宜吃的调味品：辣椒、胡椒、花椒</div>
      <div class="line">不适宜吃的饮品：酒、蜂蜜</div>
      <div class="line">不适宜吃的糖类：饴糖</div>
      <div class="line">不适宜吃的肉类：羊肉、狗肉、鳝鱼</div>
      <div class="p">湿热体质的生活起居：</div>
      <div class="line">日常起居要求：居住环境宜干燥，通风，保持充足而有规律的睡眠。</div>
      <div class="line">日常注意事项：避免居住在低洼潮湿的地方。不要熬夜、过于劳累。盛夏暑湿较重的季节，减少户外活动时间。应戒烟酒。湿热体质的体育锻炼：</div>
      <div class="line">日常可做的运动：适合做大强度、大运动量的锻炼，如中长跑、游泳、爬山、各种球类、武术等。</div>
      <div class="line">日常运动注意事项：夏天由于气温高、湿度大，最好选择在清晨或傍晚较凉爽的时候锻炼。</div>
      <div class="p">湿热体质的情志调理</div>
      <div class="line">湿热体质的人应克制过激的情绪。合理安排自己的工作、学习，培养广泛的兴趣爱好。</div>
    </div>
    <div class="content" v-show="switchIndex === 2">
      <div class="title">阳虚质养生说</div>
      <div class="p">什么是阳虚质</div>
      <div class="line">特点与症状：阳虚体质的人总是手脚发凉，胃脘部总是怕冷，衣服比别人穿得多，耐受不了冬天的寒冷，夏天耐受不了空调房间的冷气，喜欢安静，吃（喝）凉的东西总感到不舒服，容易大便稀溏，小便颜色清，量多。</div>
      <div class="line">性格：阳虚体质的人性格多内向、沉静</div>
      <div class="line">体型：阳虚体质的人肌肉不健壮</div>
      <div class="line">阳虚体质对环境适应能力-不耐受寒邪，耐受夏季，不耐受冬季；易感受湿邪。</div>
      <div class="line">阳虚体质亚疾病状态及发病倾向-发病多为寒症，易患水涨、泄泻、阳痿等。</div>
      <div class="p">阳虚体质的养生方法 -阳虚体质的饮食宜与忌：</div>
      <div class="line">宜：可多食具有甘温益气之品</div>
      <div class="line">适宜吃的肉类：平时可多食牛肉、羊肉、狗肉、鳝鱼</div>
      <div class="line">适宜吃的菜类：韭菜、生姜、辣椒、芫荽、葱、蒜、芥末</div>
      <div class="line">适宜吃的调味品：花椒、胡椒</div>
      <div class="line">忌：少食冷寒凉食物</div>
      <div class="line">不适宜吃的水果：梨、西瓜、荸荠</div>
      <div class="line">不适宜吃的菜类：韭菜、生姜、芫荽</div>
      <div class="line">不适宜吃的调味品：辣椒、胡椒、花椒</div>
      <div class="line">不适宜吃的饮品：绿茶</div>
      <div class="p">阳虚体质的生活起居：</div>
      <div class="line">日常起居要求：居住环境应空气流通，可在自然环境下纳凉，在阳光充足的情况下适当进行户外运动。保持足够睡眠。</div>
      <div class="line">日常注意事项：避免居住在低洼潮湿的地方。不要熬夜、过于劳累。盛夏暑湿较重的季节，减少户外活动时间。应戒烟酒。湿热体质的体育锻炼：</div>
      <div class="line">日常注意事项：秋冬注意保暖。夏季避免长时间呆在空调房间中，不要睡在穿风的过道上及露天空旷之处。平时注意足下、背部及下腹部丹田部位的防寒保暖。防止出汗过多可做一些舒缓柔和的运动，如慢跑、散步、打太极拳、做广播体操。自行按摩气海、足三里、涌泉等穴位，或经常灸足三里、关元，可适当洗桑拿、温泉浴。</div>
      <div class="line">日常运动注意事项：夏天不宜做过分剧烈的运动，冬天避免在大风、大寒、大雾、大雪及空气污染的环境中锻炼。</div>
      <div class="p">阳虚体质的情志调理</div>
      <div class="line">多与别人交谈、沟通。对待生活中不顺心的事情，要从正反两方面分析，及时消除情绪中的消极因素。平时可多听一些激扬、高亢、豪迈的音乐以</div>
    </div>
    <div class="content" v-show="switchIndex === 3">
      <div class="title">阴虚质养生说</div>
      <div class="p">什么是阴虚质</div>
      <div class="line">特点与症状：经常感觉身体、 脸上发热，耐受不了夏天的暑热，皮肤干燥，经常感到手脚心发热，面颊潮红或偏红，感到眼睛干涩，经常口干咽燥，容易失眠，经常大便干结。</div>
      <div class="line">性格：阴虚体质的人性 格急躁、外向好动，活泼</div>
      <div class="line">体型：阴虚体质的人体 型瘦长</div>
      <div class="line">阴虚体质的亚疾 病状态及发病倾向-阴虚体质的人易 患咳嗽、糖尿病、闭经、发热等。</div>
      <div class="line">阴虚体质对环境适应能力-阴虚体质的人 平时不耐暑热、干燥、耐受冬季，不耐受夏季。</div>
      <div class="p">阴虚体质的养生方法 -阴虚体质的饮食宜与忌：</div>
      <div class="line">宜：可多食甘凉 滋润之品</div>
      <div class="line">适宜吃的肉类：瘦猪肉、鸭肉、龟、鳖</div>
      <div class="line">适宜吃的菜类：冬瓜、百合、荸荠、芝麻</div>
      <div class="line">适宜吃的水产品 ：海蜇</div>
      <div class="line">适宜吃的豆类： 绿豆、赤小豆</div>
      <div class="line">忌：少食性温燥 烈之品</div>
      <div class="line">不适宜吃的菜类 ：韭菜</div>
      <div class="line">不适宜吃的调味 品：辣椒、葱、蒜</div>
      <div class="line">不适宜吃的干果 ：葵花籽</div>
      <div class="line">不适宜吃的肉类 ：羊肉、狗肉</div>
      <div class="p">阳虚体质的生活起居：</div>
      <div class="line">日常起居要求：起居应有规律， 居住环境宜安静，中午保持一定的午休时间。</div>
      <div class="line">日常注意事项：睡前不要饮茶 、锻炼和玩游戏，应早睡早起，避免熬夜、剧烈运动和在高温酷暑下工作。宜节制房事。戒烟酒。</div>
      <div class="line">只适合做中小强 度、间断性身体锻炼，可选择太极拳、太极剑、气功等动静结合的传统健身项目。皮肤干燥甚者，可多游泳。</div>
      <div class="line">日常运动注意事项：锻炼时要控制出 汗量，及时补充水分。不宜洗桑拿</div>
      <div class="p">阴虚体质的情志调理</div>
      <div class="line">平时宜克制情绪 ，遇事要冷静，正确对待顺境和逆境。可以用练书法、下棋来怡情悦性，用旅游来寄情山水、陶冶情操。平时多听一些曲调舒缓</div>
    </div>
    <div class="content" v-show="switchIndex === 4">
      <div class="title">血虚质养生说</div>
      <div class="p">什么是血虚质</div>
      <div class="line">特点与症状：皮肤常在不知不觉中出现紫瘀斑（皮下出血），皮肤常干燥、粗糙，常常出现疼痛，面色晦黯或有色素沉着、黄褐色斑块，眼眶经常暗黑，眼睛经常有红丝（充血），刷牙时牙龈容易出血。</div>
      <div class="line">性格：血瘀体质的人容易烦躁，健忘，性情急躁。</div>
      <div class="line">体型：血瘀体质瘦人居多。</div>
      <div class="line">血虚体质的亚疾 病状态及发病倾向-血瘀体质的人易患出血、中风、冠心病等。</div>
      <div class="line">血虚体质对环境适应能力-血瘀体质不耐受风邪、寒邪。</div>
      <div class="p">血虚体质的养生方法 -阴虚体质的饮食宜与忌：</div>
      <div class="line">宜：可多食具有活血、散结、行气、疏肝解郁作用的食物。</div>
      <div class="line">适宜吃的水果：金橘、橙、柚、桃、李、山楂</div>
      <div class="line">适宜吃的菜类：萝卜、胡萝卜</div>
      <div class="line">适宜吃的花草、饮品类：玫瑰花、绿茶</div>
      <div class="line">适宜吃的水产品：海藻、海带、紫菜</div>
      <div class="line">适宜吃的调味品：醋</div>
      <div class="line">适宜吃的豆类：黑豆</div>
      <div class="line">不适宜吃的肉类 ：少食肥猪肉等滋腻之品。</div>
      <div class="p">血虚体质的生活起居：</div>
      <div class="line">日常起居要求：作息时间已宜规律，可早睡早起，保持足够的睡眠。</div>
      <div class="line">日常注意事项但不可过于安逸，以免气机郁滞而致血行不畅。</div>
      <div class="line">可进行一些有助于促进气血运行的运动项目，如太极拳、太极剑、各种舞蹈、步行健身法、徒手健身操等。保健按摩可使经络畅通。</div>
      <div class="line">日常运动注意事项：血瘀质的人在运动时如出现胸闷、呼吸困难、脉搏显著加快等不适症状，应停止运动，去医院进一步检查。</div>
      <div class="p">血虚体质的情志调理</div>
      <div class="line">及时消除不良情绪，保持心情愉快，防止郁闷不乐而致气机不畅。可多听一些抒情柔缓的音乐来调节情绪。</div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      switchIndex: 0,
      switchs: Object.freeze(['湿热质', '兼有', '阳虚质', '阴虚质', '血瘀质'])
    }
  },
  methods: {
    switchClick (index) {
      this.switchIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.assess {
  overflow-y: scroll;
  position: relative;
  width: 95%;
  height: 100%;
  margin: 0 auto;

  &::-webkit-scrollbar {
      width: 0;
    }

  .switch {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    padding: 0 22px;
    box-sizing: border-box;
    border-bottom: 1px #000 solid;
    box-sizing: border-box;
    background-color: #fff;

    .title {
      color: #000;
      font-size: 16px;
    }

    .item {
      width: 146px;
      height: 38px;
      line-height: 38px;
      margin-left: 16px;
      text-align: center;
      font-size: 16px;
      color: #fff;
      background-color: #D2D2D2;
      border-radius: 4px;
    }

    .item1 {
      background-color: #000;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #000;

    .title {
      margin: 20px 0;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
    }

    .p {
      margin-top: 20px;
      font-size: 16px;
      font-weight: 600;
    }

    .line {
      margin-top: 9px;
    }
  }
}
</style>
